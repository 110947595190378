import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';

export default {
	// GET API

	getAllProductsFromPurchasing(search = null) {
		return this.handelAlert((res, rej, loadObj) => {
			const url = search ? `${base}/api/purchasing/product-list?search=${search}` : `${base}/api/purchasing/product-list`;
			axios
				// .get(`${base}/api/estimations/get-estimation-number`, {
				.get(url, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getEstimateProducts(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/estimations/products/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	uploadPayementTraceFile(fmData) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/upload-payment-trace-file`, fmData, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	sumbitEstimation(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/estimations`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	moveDraftToEstimate(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/estimations/draft/${id}/movetoestimate`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	moveDraftToInvoice(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/estimations/draft/${id}/movetoinvoice`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getDraftNumber(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/drafts/get-draft-number`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	deleteDraft(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/estimations/draft/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	submitDraft(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/drafts`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateEstimation(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/estimations/${id}`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getEstimation(id) {
		return this.handelAlert((res, rej, loadObj) => {
			const url = id ? `${base}/api/estimations/sql?status=${id}` : `${base}/api/estimations/sql`;
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					console.log(response.data);
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	async getQoutationById(id) {
		// return this.handelAlert((res, rej, loadObj) => {
		// 	;
		// }, false);

		const result = await axios.get(`${base}/api/qoutations/${id}`, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
					}`
			}
		});

		return result;
	},
	async getEstimationByIdWithParam(id, callbackParam) {
		// return this.handelAlert((res, rej, loadObj) => {
		// 	;
		// }, false);

		const result = await axios.get(`${base}/api/estimations/${id}?callback-action-accept-order=${callbackParam}`, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
					}`
			}
		});

		return result;
	},
	async getEstimationById(id) {
		// return this.handelAlert((res, rej, loadObj) => {
		// 	;
		// }, false);

		const result = await axios.get(`${base}/api/estimations/${id}`, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
					}`
			}
		});

		return result;
	},
	getDraftById(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/estimations/draft/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	async listingEstimation(statusType, filters, search = null, sortByFilter = null) {
		let url = `${base}/api/estimations/sql?${statusType ? 'statusType=' + statusType + '&' : ''}`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		if (sortByFilter) {
			url += `&sortBy=${sortByFilter?.field}&sortOrder=${sortByFilter?.direction}`;
		}

		const result = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
					}`
			}
		});

		return result;
		// }, false);
	},
	downloadListingEstimation(status, filters, search = null, currency, saleType) {
		let url = `${base}/api/estimations/sql/export-results?${status ? 'statusType=' + status + '&' : ''}`;

		Object.keys(filters).forEach((key) => {
			// if (key != 'startDate' && key != 'endDate') {
			filters[key]?.value && (url += `${key}=${filters[key]?.value}&`);
			// } else {
			// 	url += `${key}=${filters[key]}&`;
			// }
		});

		if (search) {
			url += `search=${search}&`;
		}

		if (currency) {
			url += `currency=${currency}&`;
		}
		if (saleType) {
			url += `statusType=${(saleType == 'quotations') ? 2 : 3}&`;
		}
		url += `isExcel=1`;
		url = url.endsWith('&') ? url.slice(0, -1) : url;

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(url, status, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					},
					responseType: 'blob'
				})
				.then((response) => {
					console.log(response);
					res(response.data);
				})
				.catch((rej) => {
					res(rej?.response?.status);
				});
		}, false);
	},

	exportQuotation(status, filters, search = null, currency) {
		let url = `${base}/api/quotations/export-results?`;

		Object.keys(filters).forEach((key) => {
			filters[key]?.value && (url += `${key}=${filters[key]?.value}&`);
		});

		if (search) {
			url += `search=${search}&`;
		}

		if (currency) {
			url += `currency=${currency}&`;
		}
		url += `isExcel=1`;
		url = url.endsWith('&') ? url.slice(0, -1) : url;

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(url, status, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					},
					responseType: 'blob'
				})
				.then((response) => {
					console.log(response);
					res(response.data);
				})
				.catch((rej) => {
					res(rej?.response?.status);
				});
		}, false);
	},

	exportInvoice(status, filters, search = null, currency) {
		let url = `${base}/api/invoices/export-results?`;

		Object.keys(filters).forEach((key) => {
			filters[key]?.value && (url += `${key}=${filters[key]?.value}&`);
		});

		if (search) {
			url += `search=${search}&`;
		}

		if (currency) {
			url += `currency=${currency}&`;
		}
		url += `isExcel=1`;
		url = url.endsWith('&') ? url.slice(0, -1) : url;

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(url, status, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					},
					responseType: 'blob'
				})
				.then((response) => {
					console.log(response);
					res(response.data);
				})
				.catch((rej) => {
					res(rej?.response?.status);
				});
		}, false);
	},

	quickSummaryOrder(status, filters, search = null, currency) {
		let url = `${base}/api/estimations/sql/quick-stats?${status ? 'statusType=' + status + '&' : ''}`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					console.log(response);
					res(response.data);
				})
				.catch((rej) => {
					res(rej?.response?.status);
				});
		}, false);
	},
	listingEstimationApiURL(status, filters, search = null, sortByFilter = null) {
		let url = `${base}/api/estimations/sql?${status ? 'status=' + status + '&' : ''}`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		if (sortByFilter) {
			url += `&sortBy=${sortByFilter?.field}&sortOrder=${sortByFilter?.direction}`;
		}

		return url;
	},
	getEstimationFilter() {
		return this.handelAlert((res, rej, loadObj) => {
			const url = `${base}/api/estimations/filter/get`;
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getInvoiceFilter() {
		return this.handelAlert((res, rej, loadObj) => {
			const url = `${base}/api/invoices/filter/get`;
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	deleteEstimation(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/qoutations/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	deleteInvoice(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/estimations/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	cancelEstimation(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/estimations/${id}/cancel`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	cancelInvoice(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/invoice/${id}/cancel`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	restoreEstimation(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/estimations/${id}/restore`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	uploadTTfile(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/uploads/estimations/tt-files`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	acceptedEstimate(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/estimations/accept`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	resendAcceptedOrderRequest(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/estimations/resend-accept-request`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	approveEstimate(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/estimations/approve`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	resendAgreementEstimate(estimation_id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/estimations/resend-agreement/${estimation_id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	// approveEstimate(estimation_id) {
	// 	return this.handelAlert((res, rej, loadObj) => {
	// 		axios
	// 			.post(`${base}/api/estimations/approve`, {
	// 				headers: {
	// 					Authorization: `Bearer ${
	// 						JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
	// 					}`
	// 				}
	// 			})
	// 			.then((response) => {
	// 				res(response.data);
	// 			})
	// 			.catch((rej) => {
	// 				apiResponseErrorHandler(rej);
	// 				res(rej.response.data);
	// 			});
	// 	}, false);
	// },

	changeestimateStatus(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/update-estimation-status`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	// addregisterpayment(data) {
	// 	return this.handelAlert((res, rej, loadObj) => {
	// 		axios
	// 			.post(`${base}/api/add-register-payment`, data, {
	// 				headers: {
	// 					Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
	// 						}`
	// 				}
	// 			})
	// 			.then((response) => {
	// 				res(response.data);
	// 			})
	// 			.catch((rej) => {
	// 				apiResponseErrorHandler(rej);
	// 				res(rej.response.data);
	// 			});
	// 	}, false);
	// },
	addInvoicePayment(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/estimations/payment`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateEstimatePayment(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/estimations/payment/update`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	deleteEstimatePayment(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/estimations/payment/${data}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getEstimatePaymentDetails(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/estimations/payment/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	addEstimatePaymentFile(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/upload/estimations/payments`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	newEstimateNote(est_id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/estimations/${est_id}/notes`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	uploadEstimateNoteAttachment(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/uploads/estimations/${id}/notes`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	downloadEstimate(est_id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/estimations/${est_id}/get-estimate-file`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					},
					responseType: 'blob'
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	removeEstimateNote(est_id, note_id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/estimations/${est_id}/notes/${note_id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	// postPurchaseOrder(data) {
	// 	return this.handelAlert((res, rej, loadObj) => {
	// 		axios
	// 			.post(`${base}/api/purchasing/create`, data, {
	// 				headers: {
	// 					Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
	// 						}`
	// 				}
	// 			})
	// 			.then((response) => {
	// 				res(response.data);
	// 			})
	// 			.catch((rej) => {
	// 				apiResponseErrorHandler(rej);
	// 				res(rej.response.data);
	// 			});
	// 	}, false);
	// },
	// createInvoicePurchaseOrder(data) {
	// 	return this.handelAlert((res, rej, loadObj) => {
	// 		axios
	// 			.post(`${base}/api/estimations/purchase-order/create`, data, {
	// 				headers: {
	// 					Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
	// 						}`
	// 				}
	// 			})
	// 			.then((response) => {
	// 				res(response.data);
	// 			})
	// 			.catch((rej) => {
	// 				apiResponseErrorHandler(rej);
	// 				res(rej.response.data);
	// 			});
	// 	}, false);
	// },
	createDraftPurchaseOrder(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/estimations/purchase-order/draft`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	sendPurchaseOrderEmailToSupplier(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchasing/send-email-supplier`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	createpurchase(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/create/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	createbackorder(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/back-orders/create/${id}`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},
	getbackorders(search = null) {
		return this.handelAlert((res, rej, loadObj) => {
			console.log(search?.search, 'search');
			const url = search ? `${base}/api/back-orders?search=${search?.search}` : `${base}/api/back-orders/`;
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response?.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},

	freestockByProductIds(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/product/free-stock`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	processBackorders() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/back-orders/process`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	seachEstimatebytext(data) {
		return this.handelAlert((res, rej, loadObj) => {
			const url = `${base}/api/estimations?search=${data?.text}&status=${data?.status}`;
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	// Puurchase
	purchaseList(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list?status=${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	purchaseListFilter(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/filter?status=${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	purchaseListById(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	backOrderMovetoFound(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchase-list/back-orders/move-sourced`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	movetoFound(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchase-list/move-sourced`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	purcaseListModel() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/search-product`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	assignInspection(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchase-list/assign-task-inspection`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	assignCollect(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchase-list/assign-task-collect`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	purchaseStatuschange(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchase-list/update-status`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	editpurchasemodel(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchase-list/edit-products`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	searchpurchaseList(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list?status=${data.status}&search=${data.text}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	downloadfilterpurchaselist(status, filters, search = null, currency) {
		let url = `${base}/api/purchase-list/export-results?${status ? 'status=' + status + '&' : ''}`;

		Object.keys(filters).forEach((key) => {
			// if (key != 'startDate' && key != 'endDate') {
			url += `${key}=${filters[key].value}&`;
			// } else {
			// 	url += `${key}=${filters[key]}&`;
			// }
		});

		if (search) {
			url += `search=${search}&`;
		}

		if (currency) {
			url += `currency=${currency}&`;
		}
		// if (excel == 1) {
		url += `isExcel=1`;
		// }
		url = url.endsWith('&') ? url.slice(0, -1) : url;

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(url, status, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					},
					responseType: 'blob'
				})
				.then((response) => {
					console.log(response);
					res(response.data);
				})
				.catch((rej) => {
					res(rej?.response?.status);
				});
		}, false);
	},

	async filterPurchaseList(status, filters, search = null, sortByFilter = null) {
		let url = `${base}/api/purchase-list?status=${status}&`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		if (sortByFilter) {
			url += `&sortBy=${sortByFilter?.field}&sortOrder=${sortByFilter?.direction}`;
		}

		const result = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
					}`
			}
		});
		return result;
	},

	filterPurchaseListApiUrl(status, filters, search = null, sortByFilter = null) {
		let url = `${base}/api/purchase-list?status=${status}&`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		if (sortByFilter) {
			url += `&sortBy=${sortByFilter?.field}&sortOrder=${sortByFilter?.direction}`;
		}

		return url;
	},
	// getPageOverviewCounter() {
	// 	return this.handelAlert((res, rej, loadObj) => {
	// 		axios
	// 			.get(`${base}/api/counters?page=overview`, {
	// 				headers: {
	// 					Authorization: `Bearer ${
	// 						JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
	// 					}`
	// 				}
	// 			})
	// 			.then((response) => {
	// 				res(response.data);
	// 			})
	// 			.catch((rej) => {
	// 				apiResponseErrorHandler(rej);
	// 				res(rej.response.data);
	// 			});
	// 	}, false);
	// },

	async getPageOverviewCounter() {
		const result = await axios.get(`${base}/api/counters?page=overview`, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
					}`
			}
		});

		return result;
	},

	async getPagePurchaseCounter() {
		const result = await axios.get(`${base}/api/counters?page=purchase`, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
					}`
			}
		});

		return result;
	},
	// Receipts
	addReceipts(fmData) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/receipts`, fmData, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},
	addReceiptsWithStock(fmData) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/receipts/stocks`, fmData, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},

	getreceipts(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/receipt/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	purchaseInboundingData(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchase-stock/save-details`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	cancelPurchaselistSourcing(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchase-list/move-cancel`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getOrderAgreements() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/get-order-agreements`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getOrderAgreementsWithResponse(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/get-order-agreements-with-response/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getClientOrderAgreement(link) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/estimations/get-order-agreement/${link}`, {})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	confirmOrderAgreement(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/estimations/client-accept`, data, {})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	sendEstimateByEmail(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/estimations/send-estimate`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	createSalesOrderFromInvoice(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/estimations/create-sales-order-invoice`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},


	async getClientInvoiceById(id) {
		// return this.handelAlert((res, rej, loadObj) => {
		// 	;
		// }, false);

		const result = await axios.get(`${base}/api/client/invoice/${id}`, {
			// headers: {
			// 	Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
			// 		}`
			// }
		});

		return result;
	},
};
